<template>
  <div class="contents detailWrap">
    <div class="title flexB">
      <h2>영농일지</h2>
    </div>
    <div class="box one">
      <div class="flex">
        <p>재배품목</p>
        <span>{{ crop }}</span>
      </div>
      <div class="flex">
        <p>영농작업</p>
        <p class="category">
          <span v-if="category.includes('category1')">농약</span>
          <span v-if="category.includes('category2')">관수</span>
          <span v-if="category.includes('category3')">해충기록</span>
          <span v-if="category.includes('category4')">장비정비</span>
          <span v-if="category.includes('category5')">수확</span>
          <span v-if="category.includes('category6')">출고</span>
          <span v-if="category.includes('category7')">기타</span>
        </p>
      </div>
      <div class="flex">
        <p>작업일</p>
        <span>{{ moment(date).format("YYYY년 MM월 DD일") }}</span>
      </div>

      <div class="diaryDetail flexL" v-if="category.includes('category1')">
        <p class="bold">농약</p>
        <div class="flex">
          <p>처리날짜</p>
          <span>{{ moment(pesticide_1).format("YYYY년 MM월 DD일") }}</span>
        </div>
        <div class="flex">
          <p>농약제품</p>
          <span>{{ pesticide_2 }}</span>
        </div>
        <div class="flex">
          <p>대상병해충</p>
          <span>{{ pesticide_3 }}</span>
        </div>
        <div class="flex">
          <p>농약제품 구입량</p>
          <span>{{ pesticide_4 }}</span>
        </div>
        <div class="flex">
          <p>농약제품 사용량</p>
          <span>{{ pesticide_5 }}</span>
        </div>
        <div class="flex">
          <p>농약처리방법</p>
          <el-checkbox-group v-model="pesticide_6">
            <el-checkbox disabled label="관수"></el-checkbox>
            <el-checkbox disabled label="기계"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex">
          <p>농약처리장비명</p>
          <el-checkbox-group v-model="pesticide_7">
            <el-checkbox disabled label="SS기"></el-checkbox>
            <el-checkbox disabled label="동력분무기"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="diaryDetail flexL" v-if="category.includes('category2')">
        <p class="bold">관수</p>
        <div class="flex">
          <p>수원</p>
          <span>{{ irrn_1 }}</span>
        </div>
        <div class="flex">
          <p>수원번호</p>
          <span>{{ irrn_2 }}</span>
        </div>
        <div class="flex">
          <p>관수시간</p>
          <span>{{ irrn_3 }}</span>
        </div>
        <div class="flex">
          <p>관수량</p>
          <span>{{ irrn_4 }}</span>
        </div>
      </div>

      <div class="diaryDetail flexL" v-if="category.includes('category3')">
        <p class="bold">해충기록</p>
        <div class="flex">
          <p>관찰날짜</p>
          <span>{{ moment(dbyhs_1).format("YYYY년 MM월 DD일") }}</span>
        </div>
        <div class="flex">
          <p>조사방법</p>
          <span>{{ dbyhs_2 }}</span>
        </div>
      </div>

      <div class="diaryDetail flexL" v-if="category.includes('category4')">
        <p class="bold">장비정비</p>
        <div class="flex">
          <p>장비종류</p>
          <span>{{ equipment_1 }}</span>
        </div>
        <div class="flex">
          <p>관리사유</p>
          <span>{{ equipment_2 }}</span>
        </div>
      </div>

      <div class="diaryDetail flexL" v-if="category.includes('category5')">
        <p class="bold">수확</p>
        <div class="flex">
          <p>생산량</p>
          <span>{{ hvst_1 }}</span>
        </div>
        <div class="flex">
          <p>생산날짜</p>
          <span>{{ moment(hvst_2).format("YYYY년 MM월 DD일") }}</span>
        </div>
      </div>

      <div class="diaryDetail flexL" v-if="category.includes('category6')">
        <p class="bold">출고</p>
        <div class="flex">
          <p>출하량</p>
          <span>{{ release_1 }}</span>
        </div>
        <div class="flex">
          <p>출하날짜</p>
          <span>{{ moment(release_2).format("YYYY년 MM월 DD일") }}</span>
        </div>
      </div>

      <div class="diaryDetail flexL" v-if="category.includes('category7')">
        <p class="bold">기타</p>
        <div class="flex" v-if="etc_1">
          <p>메모</p>
          <pre
            >{{ etc_1 }}
          </pre>
        </div>
        <div class="flex" v-if="images && images.length > 0">
          <p>사진</p>
          <div class="imgWrap">
            <ul>
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="이미지" id="img" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchDiaryDetail } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "diaryDetail",

  data() {
    return {
      moment: moment,
      diaryId: "",
      category: [],
      farmer: "",
      crop: "",
      pesticide_1: "", //농약
      pesticide_2: "",
      pesticide_3: "",
      pesticide_4: "",
      pesticide_5: "",
      pesticide_6: [],
      pesticide_7: [],
      irrn_1: "", //관수
      irrn_2: "",
      irrn_3: "",
      irrn_4: "",
      dbyhs_1: "", //해충
      dbyhs_2: "",
      equipment_1: "", // 장비
      equipment_2: "",
      hvst_1: "", //수확
      hvst_2: "",
      release_1: "", // 출고
      release_2: "",
      etc_1: "", //기타
      date: "",
      images: [],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "7");
  },
  mounted() {
    this.diaryId = this.$route.query.id;
    this.getUserDetail();
  },

  methods: {
    getUserDetail() {
      fetchDiaryDetail(this.diaryId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.farmer = res.data.data.userId;
          this.crop = res.data.data.crop;
          this.pesticide_1 = res.data.data.pesticide_1; //농약
          this.pesticide_2 = res.data.data.pesticide_2;
          this.pesticide_3 = res.data.data.pesticide_3;
          this.pesticide_4 = res.data.data.pesticide_4;
          this.pesticide_5 = res.data.data.pesticide_5;
          this.pesticide_6 = res.data.data.pesticide_6;
          this.pesticide_7 = res.data.data.pesticide_7;
          this.irrn_1 = res.data.data.irrn_1; //관수
          this.irrn_2 = res.data.data.irrn_2;
          this.irrn_3 = res.data.data.irrn_3;
          this.irrn_4 = res.data.data.irrn_4;
          this.dbyhs_1 = res.data.data.dbyhs_1; //해충
          this.dbyhs_2 = res.data.data.dbyhs_2;
          this.equipment_1 = res.data.data.equipment_1; // 장비
          this.equipment_2 = res.data.data.equipment_2;
          this.hvst_1 = res.data.data.hvst_1; //수확
          this.hvst_2 = res.data.data.hvst_2;
          this.release_1 = res.data.data.release_1; // 출고
          this.release_2 = res.data.data.release_2;
          this.etc_1 = res.data.data.etc_1; //기타
          this.images = res.data.data.images;
          this.date = res.data.data.date;
        }
      });
    },
  },
};
</script>
